import React from "react";
import GatsbyImage from "../../GatsbyImages";
import im4 from "./telefono.svg";

import "./info.css";

export const Info = ({ data }) => {
  return (
    <section className="info_wrapper">
      <div className="container">
        <h2 className="info_title">{data.title}</h2>
        <p
          className="info_description"
          dangerouslySetInnerHTML={{
            __html: data.description,
          }}
        ></p>

        <figure className="info_img">
          <GatsbyImage
            src={im4}
            alt={data.image.alternativeText}
            height={434}
          />
        </figure>
      </div>
    </section>
  );
};
