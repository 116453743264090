import React from "react";

const Arrow = ({ isOpen }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }}
    >
      <g id="Arrow">
        <rect width="24" height="24" rx="12" fill="white" fillOpacity="0.12" />
        <path
          id="Vector"
          d="M14.8401 12.4532C14.9425 12.5604 15 12.7057 15 12.8572C15 13.0087 14.9425 13.1541 14.8401 13.2612L12.3828 15.8327C12.2804 15.9398 12.1415 16 11.9967 16C11.8519 16 11.713 15.9398 11.6106 15.8327L9.15329 13.2612C9.05382 13.1534 8.99878 13.0091 9.00002 12.8593C9.00127 12.7094 9.0587 12.5661 9.15994 12.4602C9.26119 12.3542 9.39815 12.2941 9.54133 12.2928C9.68451 12.2915 9.82245 12.3491 9.92544 12.4532L11.4506 14.0001L11.4506 8.57144C11.4506 8.41988 11.5082 8.27454 11.6106 8.16737C11.713 8.0602 11.8519 8 11.9967 8C12.1415 8 12.2804 8.0602 12.3828 8.16737C12.4852 8.27454 12.5428 8.41988 12.5428 8.57144L12.5428 14.0001L14.068 12.4532C14.1704 12.3461 14.3092 12.2859 14.454 12.2859C14.5988 12.2859 14.7377 12.3461 14.8401 12.4532Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export { Arrow };
