import React from "react";
import arrow from "../../images/Arrow.webp";
import { Link, graphql, useStaticQuery } from "gatsby";
import GatsbyImages from "../GatsbyImages";
import "./posts.css";
export const Posts = ({ location, lang }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allStrapiBlog(limit: 6, sort: { date: DESC }) {
        nodes {
          id
          date
          title
          path
          locale
          category {
            Title
          }
          thumbnail {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 361, height: 208)
              }
              ext
            }
          }
          text
        }
      }
    }
  `)
    .allStrapiBlog.nodes.filter(({ locale }) => locale === lang)
    .slice(0, 3);

  const block =
    lang === "en" ? (
      <div className="blank-icon">
        <div className="container">
          <div className="icon-in">
            <h2>How do we determine the cost of a transaction?</h2>
            <div className="icon-text">
              <p>
                Investing in an agile, honest and fairly priced service. Our
                responsibility towards you goes beyond business, so we want to
                show you how we get your money to its destination quickly and
                securely.{" "}
              </p>
              <p>
                The commission for your money transfers is distributed as
                follows: 
              </p>
            </div>

            <div className="movemoney">
              <ul>
                <li>40% - Network of partners in Latin America</li>
                <li>20% - Sendity team</li>
                <li>15% - Technology and security</li>
                <li>15% - Operations and marketing</li>
                <li>10% - Financial commissions</li>
              </ul>
              <span>
                * Percentages based on 2021 data. We have simplified commissions
                with a fixed price to cover our expenses, and a small variable
                amount depending on what you send, which goes directly to our
                allies in Latin America.
              </span>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="blank-icon">
        <div className="container">
          <div className="icon-in">
            <h2>¿Cómo se determina el coste de una transacción?</h2>
            <div className="icon-text">
              <p>
                Invirtiendo en un servicio ágil, honesto y con un precio justo.
                Nuestra responsabilidad contigo va más allá del negocio por eso
                queremos mostrarte cómo hacemos posible que tu dinero llegue a
                destino de forma rápida y segura.{" "}
              </p>
            </div>

            <div className="movemoney">
              <ul>
                <li>40% - Red de socios en Latinoamérica</li>
                <li>20% - Equipo Sendity</li>
                <li>15% - Tecnología y Seguridad</li>
                <li>15% - Operaciones y Marketing</li>
                <li>10% - Comisiones financieras</li>
              </ul>
              <span>
                * Porcentajes sobre datos 2021. Hemos simplificado las
                comisiones con un precio fijo para cubrir nuestros gastos, y un
                pequeño importe variable en función de lo que envías, que va
                directamente a nuestros aliados en Latinoamérica.
              </span>
            </div>
          </div>
        </div>
      </div>
    );

  const conditionalBlock =
    location?.pathname === "/enviar-dinero" ||
    location?.pathname === "/en/send-money"
      ? block
      : null;

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-"); // Split into year, day, and month
    const date = new Date(`${year}-${month}-${day}`); // Rearrange to a valid date format (yyyy-mm-dd)

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const langFormat = lang === "es" ? "es-ES" : "en-GB";
    return new Intl.DateTimeFormat(langFormat, options)
      .format(date)
      .replace(" de 2", ", 2");
  };

  const cardTitle =
    lang === "es" ? "Novedades de la semana" : "News of the week";
  const cardContent = data.map(
    ({ category, date, thumbnail, text, title, path, locale }, index) => {
      const match = /<p[^>]*>(.*?)<\/p>/i.exec(text);
      const firstPContent = match ? match[0] : "";
      const url = lang === "en" ? `/en/blog/${path}` : `/blog/${path}`;
      const formattedDate = formatDate(date);
      return (
        <li key={index} className="">
          <Link to={url}>
            <div className="cardWrapper">
              <div className="card1">
                <GatsbyImages
                  src={thumbnail.localFile.childImageSharp.gatsbyImageData}
                  ext={thumbnail.localFile.ext}
                  className="content-image"
                  alt={thumbnail.alternativeText}
                />
                <div className="category">
                  <span>{category.Title}</span>
                </div>
              </div>
              <div className="card-content">
                <span>{formattedDate}</span>
                <div className="h5">{title}</div>
                <div
                  className="card-text"
                  dangerouslySetInnerHTML={{
                    __html: firstPContent,
                  }}
                ></div>
              </div>
            </div>
          </Link>
        </li>
      );
    },
  );

  const cardRedirectLink =
    lang === "es" ? "Ver todas las noticias" : "See all the news";

  const goToBlogLink = lang === "es" ? "/blog" : "/en/blog";
  return (
    <>
      {conditionalBlock}
      <div className="cards-block">
        <div className="container">
          <div className="cards-in">
            <h2>{cardTitle}</h2>
            <div className="cards">
              <ul className="clearfix autoplay d-flex flex-wrap">
                {cardContent}
              </ul>
            </div>
            <div className="arrow">
              <Link to={goToBlogLink}>
                <span>{cardRedirectLink}</span>
                <GatsbyImages src={arrow} alt="see all news" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
