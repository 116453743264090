import React from "react";

import { PillsHeader } from "../PillsHeader/PillsHeader";
import { LineImages } from "./LineImages";
import "./header.css";

const Header = ({ lang = "es", data }) => {
  const headerText = data.name;

  const subHeaderText = data.mobileName;

  return (
    <>
      <div
        className="container header-home"
        style={{ "--fix-header-by-lang": lang === "es" ? "304px" : "344px" }}
      >
        <div className="header-home_container">
          <h1 className="header_title">{headerText}</h1>
          <p className="header_subtitle">{subHeaderText}</p>
          <PillsHeader lang={lang} data={data.Buttons} />
        </div>
      </div>
      <div>
        <LineImages />
        <div style={{ gridArea: "3 / 1 / 4 / 2" }} className="home_logo_2">
          <div className="d-flex justify-content-center flex-row align-items-center gap-4 flex-wrap">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </>
  );
};

export { Header };
