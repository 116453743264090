import React from "react";
import { Link } from "gatsby";
import "./remesasandrecargas.css";
import { GatsbyImage } from "gatsby-plugin-image";

function printUl({ type, num, total_elements }) {
  const items = [];

  for (let i = 0; i < num; i++) {
    const image = {
      layout: "fixed",
      images: {
        fallback: {
          src: require(`./images/${type}${i + 1}.webp`).default,
        },
      },
      width: 76,
      height: 76,
    };

    items.push(
      <li className="remesas_card" key={`${type}${i + 1}`}>
        <GatsbyImage image={image} alt={`${type}${i + 1}`} />
      </li>,
    );
  }
  items.push(
    <li className="remesas_card remesas_card_num" key={`${type}`}>
      <span>{total_elements}</span>
    </li>,
  );

  return items;
}

const RemesasAndRecargas = ({ data }) => {
  const { title, country_and_operators_card } = data;

  const cards = country_and_operators_card.map(
    ({ text, total_elements, type, url }, index) => (
      <Link
        to={url}
        className={type === "recargas" ? "remesas_cards" : "recargas_cards"}
        key={index}
      >
        <ul>
          {printUl({
            type: type === "recargas" ? "operator" : "flag",
            num: 5,
            total_elements,
          })}
        </ul>

        <span className="remesasandrecargas_text">{text}</span>
      </Link>
    ),
  );
  return (
    <section className="remesasandrecargas_section">
      <div className="remesasandrecargas">
        <span>
          <p className="remesasandrecargas_description">{title}</p>
        </span>
        <span className="recargasandremesas-container">{cards}</span>
      </div>
    </section>
  );
};

export { RemesasAndRecargas };
