import React, { useEffect } from "react";
import GatsbyImage from "../../../GatsbyImages";
import image1 from "./images/line3.webp";
import image2 from "./images/line4.webp";
import "./lineImages.css";

const LineImages = () => {
  useEffect(() => {
    const handleScroll = () => {
      const velocity = 10;
      const imgRef1 = document.getElementById("imgRef1");
      const imgRef2 = document.getElementById("imgRef2");
      if (imgRef1 && imgRef2) {
        imgRef1.style.left = `${-window.scrollY / velocity}px`;
        imgRef2.style.right = `${-window.scrollY / velocity}px`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="line_image">
      <span className="shadown" />
      <span className="shadown lasts" />
      <div className="line_img">
        <GatsbyImage
          className="lineaImage_first-row"
          src={image1}
          alt="Enviar dinero"
          loading="eager"
          id="imgRef1"
        />
      </div>
      <div className="line_img">
        <GatsbyImage
          id="imgRef2"
          src={image2}
          alt="recargas"
          loading="eager"
          className="lineaImage_second-row"
        />
      </div>
    </section>
  );
};

export { LineImages };
