import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { Arrow } from "../../../images/SVG/Arrow";

import "./pillsHeader.css";

const PillsHeader = ({ data, lang }) => {
  const [isOpen, setIsOpen] = useState(false);
  const PillsHeaderRef = useRef(null);
  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        PillsHeaderRef.current &&
        !PillsHeaderRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };

    document?.addEventListener("pointerdown", handleClickOutside);
    return () => {
      document?.removeEventListener("pointerdown", handleClickOutside);
    };
  }, []);

  return (
    <span className="d-grid gap-1 position-relative" ref={PillsHeaderRef}>
      <button className="button-container" onClick={handleClick}>
        <span>{lang === "es" ? "Enviar" : "Send"}</span>
        <Arrow isOpen={isOpen} />
      </button>
      <ul
        className="moneymenuservices"
        style={{ display: isOpen ? "grid" : "none" }}
      >
        {data.map(({ type, text, url, img }, index) => {
          const altText = {
            send: "fast",
            recharge: "mobile",
            food: "food",
          };
          const icon = (
            <img
              loading="lazy" // Consider using lazy loading for better performance
              src={img.url}
              alt={altText[type]}
              width="24" // Adjust width and height according to your design
              height="24"
            />
          );

          const placeholder = (
            <span
              style={{
                backgroundColor: "transparent",
                width: "24px", // Adjust width and height according to your design
                height: "24px",
                display: "inline-block", // Ensure the placeholder takes up space
              }}
            />
          );

          const isExternal = url.startsWith("http");
          const content = (
            <li className="pillHeader-container">
              {icon || placeholder}
              <span className="pillsHeader">{text}</span>
            </li>
          );
          let link;
          if (isExternal) {
            link = (
              <a href={url} key={index}>
                {content}
              </a>
            );
          }
          if (!isExternal) {
            link = (
              <Link to={url} key={index}>
                {content}
              </Link>
            );
          }

          return link;
        })}
      </ul>
    </span>
  );
};

export { PillsHeader };
