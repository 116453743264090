import React, { useLayoutEffect, useRef } from "react";
import GatsbyImages from "../../GatsbyImages";

// const { ScrollObserver, valueAtPercentage } = lazy(() =>
//   import("./../../../../attjs"),
// );

import { ScrollObserver, valueAtPercentage } from "./../../../../attjs";

import "./whatis.css";

const Whatis = ({ data = {} }) => {
  const cardContainerRef = useRef();
  useLayoutEffect(() => {
    if (!document) return;
    const cardsContainer = cardContainerRef.current.style;
    const cards = document.querySelectorAll(".whatis_cards-container");

    cardsContainer.setProperty("--cards-count", cards.length);
    cardsContainer.setProperty("--card-height", `${cards[0].clientHeight}px`);

    Array.from(cards).forEach((card, index) => {
      const offsetTop = 20 + index * 20;
      card.style.paddingTop = `${offsetTop}px`;
      if (index === cards.length - 1) {
        return;
      }
      const toScale = 1 - (cards.length - 1 - index) * 0.1;
      const nextCard = cards[index + 1];
      const cardInner = card.querySelector(".whatis-card__inner");
      ScrollObserver.Element(nextCard, {
        offsetTop,
        offsetBottom: window.innerHeight - card.clientHeight,
      }).onScroll(({ percentageY }) => {
        cardInner.style.scale = valueAtPercentage({
          from: 1,
          to: toScale,
          percentage: percentageY,
        });
        cardInner.style.filter = `brightness(${valueAtPercentage({
          from: 1,
          to: 0.6,
          percentage: percentageY,
        })})`;
      });
    });
  }, []);

  return (
    <section className="whatis_section">
      <div className="whatis">
        <span>
          <p className="whatis_description">{data.title}</p>
        </span>
        <ul className="cardsSS" ref={cardContainerRef}>
          {data.servicesDesc.map(({ icon, title, description }, index) => {
            return (
              <li className="whatis_cards-container" data-index="0" key={index}>
                <div className="whatis-card__inner">
                  {icon.url && (
                    <GatsbyImages
                      src={icon.url}
                      alt={
                        icon.alternativeText || "icono descriptivo del servicio"
                      }
                      className="card__image"
                      height={70}
                      width={60}
                      ext={icon.ext}
                    />
                  )}
                  <div>
                    <h3 className="whatis_card_title">{title}</h3>
                    <p className="whatis_card_description">{description}</p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export { Whatis };

// gatsbyImage obj emulation
//  const image = {
//    layout: "fixed",
//    images: {
//      fallback: {
//        src: icon.localFile.publicURL,
//      },
//    },
//    width: 70,
//    height: 60,
//  };
