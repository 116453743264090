import React from "react";
import "./aboutTeam.css";

function ranking({ max, num }) {
  const start = [];

  for (let i = 0; i < max; i++) {
    start.push(
      <span key={i} className={num <= i ? "fa star" : "fa star active"}></span>,
    );
  }

  return start;
}

export const AboutTeam = ({ data, lang }) => (
  <section className="team_wrapper">
    <div className="container">
      <h2 className="team_title">{data.title}</h2>
      <ul className="team_cards-wrapper">
        {data.Team.map(({ image, name, description, percent }, index) => {
          return (
            <li key={index} className="team_card">
              <div className="team_card-content">
                <h5 className="team_card-title">
                  <b>{name}</b>
                </h5>
                <p className="team_card-description">{description}</p>

                <div className="team_rating">
                  <span className="stars-container">
                    {ranking({ max: 5, num: percent })}
                  </span>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  </section>
);
