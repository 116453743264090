import React from "react";
import GatsbyImages from "../../GatsbyImages";

import "./block2.css";
export const Block2 = ({ data }) => {
  const imgcomp = (
    <GatsbyImages
      src={data.image.localFile.childImageSharp}
      ext={data.image.ext}
      imgClassName="block2-img"
      alt={data.image.alternativeText || "dont miss discount image"}
      layout={"constrained"}
      objectFit={"contain"}
    />
  );
  //con fixed ganmo calidad
  //si no menos calidad
  const h2comp = (
    <h2
      className="block2-title"
      dangerouslySetInnerHTML={{
        __html: data.title,
      }}
    />
  );

  const pcomp = (
    <p
      className="block2-description"
      dangerouslySetInnerHTML={{
        __html: data.text,
      }}
    />
  );

  const button = data.link.map((val, index) => {
    return (
      <a href={val.path} key={index} className="block2-button">
        {val.name}
      </a>
    );
  });

  return (
    <section className="block2-container">
      <span className="block2-grid">
        {imgcomp}
        <div className="block2-content">
          {h2comp}
          {pcomp}
          {button}
        </div>
      </span>
    </section>
  );
};
